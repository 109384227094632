import Box from '@mui/material/Box'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { locale } from '../../../locales'
import { ClientSelection } from '../../../types/entities/ClientPermission'
import Link from '@mui/material/Link'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../../components/Data/VirtuosoMuiTable'
import {
  addOdsCodeColumn,
  getLocaleDateMedWithWeekDay,
} from '../../../utils/Helpers'
import { CommercialsProductInvoiceDetailsItem } from '../entities/Commercials'
import theme from '../../../styles/theme'
import {
  SortArrayByPropertyKeyArgs,
  sortArrayByPropertyKey,
} from '../../../utils/SortingUtils'

const translation =
  locale.translation.AnalyticsPage.TabCommercials
    .CommercialsProductInvoiceDetails

const renderInvoiceLink = (
  rowData: CommercialsProductInvoiceDetailsItemLocal
): ReactNode => {
  return (
    <Link
      sx={{
        color: theme.palette.text.primary,
        textDecoration: 'underline',
        textDecorationColor: 'inherit',
        textDecorationThickness: '2px',
        '&:hover': {
          textDecoration: 'underline',
          textDecorationColor: 'inherit',
          textDecorationThickness: '2px',
          color: theme.palette.text.primary,
        },
      }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        rowData.setOpenInvoice(`${rowData.bookInId}|${rowData.odsCode}`)
      }}
    >
      {rowData.invoiceNumber}
    </Link>
  )
}

const getColumnDefinitions = (
  selectedClient: ClientSelection | null,
  odsToPharmacyMapping: { [key: string]: string } | null,
  isGenericGroup: boolean
): Array<VirtuosoColumn<CommercialsProductInvoiceDetailsItemLocal>> => {
  const result: Array<
    VirtuosoColumn<CommercialsProductInvoiceDetailsItemLocal>
  > = [
    {
      dataKey: 'invoiceNumber',
      label: translation.ColumnsInvoiceNumber,
      sortable: true,
      customCellNode: (value, rowData) => renderInvoiceLink(rowData),
      widthCss: '120px',
    },
    {
      dataKey: 'invoiceDate',
      label: translation.ColumnsInvoiceDate,
      sortable: true,
      valueTransformer: (rowData) => {
        return getLocaleDateMedWithWeekDay(rowData.invoiceDate)
      },
      widthCss: '120px',
    },
    {
      dataKey: 'quantity',
      label: translation.ColumnsQuantity,
      sortable: true,
      widthCss: '80px',
    },
    {
      dataKey: 'unitPrice',
      label: translation.ColumnsPricePerUnit,
      sortable: true,
      currency: true,
      widthCss: '80px',
    },
    {
      dataKey: 'supplierPrice',
      label: translation.ColumnSupplierPrice,
      sortable: true,
      currency: true,
      widthCss: '80px',
    },
    {
      dataKey: 'totalPaid',
      label: translation.ColumnsTotalPrice,
      sortable: true,
      currency: true,
      widthCss: '80px',
    },
    {
      dataKey: 'tariffPrice',
      label: translation.ColumnsTariff,
      sortable: true,
      currency: true,
      infoTooltip: translation.InfoTariff,
      widthCss: '80px',
    },
    {
      dataKey: 'concessionPrice',
      label: translation.ColumnsConcession,
      sortable: true,
      currency: true,
      infoTooltip: translation.InfoConcession,
      widthCss: '124px',
    },
    {
      dataKey: 'tradePrice',
      label: translation.ColumnsTrade,
      sortable: true,
      currency: true,
      infoTooltip: translation.InfoTrade,
      widthCss: '80px',
    },
    {
      dataKey: 'supplierDisplayName',
      label: translation.ColumnsSupplier,
      sortable: true,
      widthCss: '80px',
    },
  ]

  if (isGenericGroup) {
    result.splice(2, 0, {
      dataKey: 'productName',
      label: translation.ColumnsProductName,
      sortable: true,
    })
  }

  return addOdsCodeColumn<CommercialsProductInvoiceDetailsItemLocal>(
    selectedClient?.clientType ?? null,
    odsToPharmacyMapping,
    result,
    0,
    true
  )
}

interface CommercialsProductInvoiceDetailsProps {
  productData: CommercialsProductInvoiceDetailsItem[]
  selectedClient: ClientSelection | null
  odsToPharmacyNameMappings: { [key: string]: string } | null
  setOpenInvoice: React.Dispatch<React.SetStateAction<string | null>>
  isVmp: boolean
}

interface CommercialsProductInvoiceDetailsItemLocal
  extends CommercialsProductInvoiceDetailsItem {
  setOpenInvoice: React.Dispatch<React.SetStateAction<string | null>>
}

const getItemsLocal = (
  productData: CommercialsProductInvoiceDetailsItem[],
  setOpenInvoice: React.Dispatch<React.SetStateAction<string | null>>
) => {
  return productData.map((p) => {
    return {
      ...p,
      setOpenInvoice: setOpenInvoice,
    } as CommercialsProductInvoiceDetailsItemLocal
  })
}

const CommercialsProductInvoiceDetails: FC<
  CommercialsProductInvoiceDetailsProps
> = (props) => {
  const [sortingState, setSortingState] = useState<{
    sortArgs: SortArrayByPropertyKeyArgs
  }>({
    sortArgs: { sortingType: 'DESC', sortingPropertyKey: 'invoiceDate' },
  })

  const [sortedProductData, setSortedProductData] = useState(
    getItemsLocal(props.productData, props.setOpenInvoice)
  )

  const columnDefinitions = useMemo(
    () =>
      getColumnDefinitions(
        props.selectedClient,
        props.odsToPharmacyNameMappings,
        props.isVmp
      ),
    [props.isVmp, props.odsToPharmacyNameMappings, props.selectedClient]
  )

  useEffect(() => {
    if (props.productData) {
      const productDataClone = [...props.productData]
      sortArrayByPropertyKey(productDataClone, sortingState.sortArgs)

      setSortedProductData(
        getItemsLocal(productDataClone, props.setOpenInvoice)
      )
    }
  }, [props.productData, props.setOpenInvoice, sortingState.sortArgs])

  return (
    <>
      {props.selectedClient?.clientType && (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
          }}
          data-testid="invoice-details"
        >
          <VirtuosoMuiTable
            rows={sortedProductData}
            columns={columnDefinitions}
            sorting={{
              dataKey: sortingState.sortArgs.sortingPropertyKey,
              sortingType: sortingState.sortArgs.sortingType,
            }}
            onSortingChanged={(sorting) =>
              setSortingState({
                sortArgs: {
                  sortingPropertyKey: sorting?.dataKey,
                  sortingType: sorting?.sortingType ?? 'NONE',
                },
              })
            }
          />
        </Box>
      )}
    </>
  )
}
export default CommercialsProductInvoiceDetails
