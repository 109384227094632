import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import { FC, useEffect, useState } from 'react'
import { locale } from '../../locales'
import theme from '../../styles/theme'
import { SxProps, Theme } from '@mui/material/styles'

const translation = locale.translation.MultiSupplierSelection

export interface MultiSelectionProps {
  id: string
  options: { [key: string]: string }
  selectedOptionKeys: string[]
  title: string
  onSelectionApplied: (selectedKeys: string[]) => void
  isDirty?: boolean
  sx?: SxProps<Theme>
}

const MultiSelection: FC<MultiSelectionProps> = ({
  isDirty = false,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedKeys, setSelectedKeys] = useState<string[]>(
    props.selectedOptionKeys
  )
  const popOverOpen = Boolean(anchorEl)
  const popOverId = popOverOpen ? `popover-${props.id}` : undefined

  useEffect(() => {
    setSelectedKeys(props.selectedOptionKeys)
  }, [props.selectedOptionKeys])

  const handleClick = (optionKey: string) => {
    const selectedKeyIndex = selectedKeys.findIndex((k) => k === optionKey)
    let nextValue: string[] | null = null
    if (selectedKeyIndex === -1) {
      nextValue = [...selectedKeys, optionKey]
    } else {
      nextValue = [...selectedKeys.filter((v, i) => i !== selectedKeyIndex)]
    }
    setSelectedKeys(nextValue!)
  }

  const handleSelectAll = () => {
    setSelectedKeys([...Object.entries(props.options).map(([key]) => key)])
  }

  const handleClearSelections = () => {
    setSelectedKeys([])
  }

  const handlePopOverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleApplyClick = () => {
    props.onSelectionApplied(selectedKeys)
    handlePopoverClose()
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handlePopOverClick}
        sx={{
          display: 'flex',
          gap: theme.spacing(1),
          fontSize: '0.7rem',
          minHeight: '6ch',
          backgroundColor: isDirty
            ? theme.palette.primary.main
            : theme.palette.common.white,
          color: !isDirty
            ? theme.palette.primary.main
            : theme.palette.common.white,
          '&:hover': {
            backgroundColor: isDirty
              ? theme.palette.primary.main
              : theme.palette.common.white,
          },
          ...props.sx,
        }}
        data-testid={`${props.id}-selection-button`}
      >
        {props.title}
      </Button>
      <Popover
        id={popOverId}
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={3}
        slotProps={{
          paper: {
            sx: {
              maxHeight: '640px',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: theme.palette.common.white,
            zIndex: 1,
            padding: theme.spacing(2),
            paddingBottom: 0,
          }}
        >
          <Button
            data-testid={`${props.id}-selection-select-all-button`}
            onClick={() => handleSelectAll()}
          >
            {translation.SelectAll}
          </Button>
          <Button
            data-testid={`${props.id}-selection-clear-selection-button`}
            onClick={() => handleClearSelections()}
            color="error"
          >
            {translation.ClearSelection}
          </Button>
        </Stack>
        <FormGroup
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingX: theme.spacing(3),
          }}
        >
          {Object.entries(props.options).map(([key, value]) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  checked={Boolean(selectedKeys.find((k) => k === key))}
                  onClick={() => handleClick(key)}
                />
              }
              label={value}
            />
          ))}
        </FormGroup>
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: theme.palette.common.white,
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            paddingX: theme.spacing(2),
          }}
        >
          <Button
            data-testid={`${props.id}-selection-apply-button`}
            variant="contained"
            disableElevation
            fullWidth
            disabled={!selectedKeys?.length}
            onClick={() => handleApplyClick()}
          >
            {translation.Apply}
          </Button>
        </Box>
      </Popover>
    </>
  )
}
export default MultiSelection
