import { FC, useEffect, useState } from 'react'
import { CommercialsProductPricesPerDateEpoch } from '../entities/Commercials'
import Box from '@mui/material/Box'
import { Chart } from 'react-chartjs-2'
import { locale } from '../../../locales'
import {
  getLocaleDateMedWithWeekDayFromEpochMilliSeconds,
  hexToRgba,
  toCurrencyString,
} from '../../../utils/Helpers'

import { ChartData, Point } from 'chart.js'
import { ChartPallete } from '../../../constants'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'
import theme from '../../../styles/theme'
import InfoTooltip from '../../../components/Interactions/InfoTooltip'

interface CommercialsProductPriceDetailsProps {
  productPricesPerDateEpoch: CommercialsProductPricesPerDateEpoch | null
  allSuppliers: { [key: string]: string }
  isVmp: boolean
}

interface PointWithSupplier extends Point {
  supplierDisplayName: string
  odsCode: string
  productName: string
}

const translation =
  locale.translation.AnalyticsPage.TabCommercials.CommercialsProductPriceDetails

const CommercialsProductPriceDetails: FC<
  CommercialsProductPriceDetailsProps
> = ({ productPricesPerDateEpoch, allSuppliers, isVmp }) => {
  const [chartData, setChartData] = useState<ChartData<
    'scatter' | 'line'
  > | null>(null)

  const [minDate, setMinDate] = useState<string | null>(null)
  const [maxDate, setMaxDate] = useState<string | null>(null)

  useEffect(() => {
    if (productPricesPerDateEpoch) {
      const labels: Array<string> = []
      const averagePrices: Array<number | null> = []
      const tariffs: Array<number | null> = []
      const concessions: Array<number | null> = []
      const tradePrices: Array<number | null> = []
      const scatteredPrices: Array<PointWithSupplier> = []

      const productPricesKeys = Object.keys(productPricesPerDateEpoch)

      setMinDate(DateTime.fromSeconds(Number(productPricesKeys[0])).toISODate())
      setMaxDate(
        DateTime.fromSeconds(
          Number(productPricesKeys[productPricesKeys.length - 1])
        ).toISODate()
      )

      for (const itemKey of productPricesKeys) {
        labels.push(DateTime.fromSeconds(Number(itemKey)).toISODate())
        averagePrices.push(
          productPricesPerDateEpoch[itemKey].averageUnitPrice ?? null
        )
        tariffs.push(productPricesPerDateEpoch[itemKey].tariffPrice ?? null)
        concessions.push(
          productPricesPerDateEpoch[itemKey].concessionPrice ?? null
        )
        tradePrices.push(productPricesPerDateEpoch[itemKey].tradePrice ?? null)
        if (
          (productPricesPerDateEpoch[itemKey].unitPricesWithSupplier?.length ??
            0) > 0
        ) {
          for (const unitPriceWithSupplier of productPricesPerDateEpoch[itemKey]
            .unitPricesWithSupplier!) {
            scatteredPrices.push({
              x: Number(itemKey) * 1000, // Internally chartjs is milliseconds from epoch
              y: unitPriceWithSupplier.unitPrice,
              supplierDisplayName:
                allSuppliers[unitPriceWithSupplier.supplierId] ?? '',
              odsCode: unitPriceWithSupplier.odsCode,
              productName: unitPriceWithSupplier.productName,
            })
          }
        }
      }

      const scatterColorRgba = hexToRgba(ChartPallete[0], 0.5)

      setChartData({
        labels,
        datasets: [
          {
            type: 'line',
            label: translation.LabelPricePerUnit,
            data: averagePrices,
            borderColor: ChartPallete[1],
            backgroundColor: ChartPallete[1],
            spanGaps: true,
            pointRadius: 4,
            tension: 0.1,
            fill: false,
            cubicInterpolationMode: 'monotone',
          },
          {
            type: 'scatter',
            label: translation.Hide,
            data: scatteredPrices,
            borderColor: scatterColorRgba,
            backgroundColor: scatterColorRgba,
            spanGaps: false,
            showLine: false,
            pointRadius: 3,
            tension: 0.1,
            fill: false,
            cubicInterpolationMode: 'monotone',
          },
          {
            type: 'line',
            label: translation.LabelTariff,
            data: tariffs,
            borderColor: ChartPallete[2],
            backgroundColor: ChartPallete[2],
            borderWidth: 2,
            spanGaps: false,
            pointRadius: 1,
            tension: 0.1,
            fill: false,
            cubicInterpolationMode: 'monotone',
            tooltipText: translation.TariffTooltip,
          } as any,
          {
            type: 'line',
            label: translation.LabelConcession,
            data: concessions,
            borderColor: ChartPallete[4],
            backgroundColor: ChartPallete[4],
            borderWidth: 2,
            spanGaps: false,
            pointRadius: 1,
            tension: 0.1,
            fill: false,
            cubicInterpolationMode: 'monotone',
          },
          {
            type: 'line',
            label: translation.LabelTrade,
            data: tradePrices,
            borderColor: ChartPallete[5],
            backgroundColor: ChartPallete[5],
            borderWidth: 2,
            spanGaps: false,
            pointRadius: 1,
            tension: 0.1,
            fill: false,
            cubicInterpolationMode: 'monotone',
            tooltipText: translation.TradeTooltip,
          },
        ],
      })
    }
  }, [productPricesPerDateEpoch, allSuppliers])

  return (
    <>
      <Box
        data-testid="pricing-details"
        sx={{
          display: 'flex',
          flexGrow: 1,
          maxHeight: { xs: '500px', xl: 'none' },
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!productPricesPerDateEpoch && (
          <Box sx={{ flexGrow: 1, alignSelf: 'flex-start', textAlign: 'left' }}>
            <Typography>{locale.translation.Common.NoData}</Typography>
          </Box>
        )}
        {minDate && maxDate && chartData && (
          <>
            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              {chartData.datasets.map((ds) => {
                if (ds.label === translation.Hide) {
                  return null
                }
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: 32,
                        height: 12,
                        backgroundColor: ds.backgroundColor as string,
                        mr: 1,
                      }}
                    />
                    <Typography
                      variant="body2"
                      color={theme.palette.grey[800]}
                      sx={{ mr: theme.spacing(0.3) }}
                    >
                      {ds.label}
                    </Typography>
                    {(ds as any).tooltipText && (
                      <InfoTooltip text={(ds as any).tooltipText} />
                    )}
                  </Box>
                )
              })}
            </Box>
            <Chart
              type={'scatter'}
              data={chartData}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        if (context.datasetIndex === 1) {
                          const dataItem = context.dataset.data[
                            context.dataIndex
                          ] as PointWithSupplier
                          let result = `${
                            dataItem.supplierDisplayName
                          }: ${toCurrencyString(dataItem.y)}, ${
                            dataItem.odsCode
                          }`
                          if (isVmp) {
                            result = `${dataItem.productName}, ${result}`
                          }
                          return result
                        }
                        const value: number | null =
                          (context.raw as number) ?? null
                        return `${context.dataset.label}: ${toCurrencyString(
                          value
                        )}`
                      },
                      title: function (context) {
                        return (
                          getLocaleDateMedWithWeekDayFromEpochMilliSeconds(
                            context[0].parsed.x
                          ) ?? ''
                        )
                      },
                    },
                  },
                  datalabels: {
                    display: false,
                  },
                },
                scales: {
                  y: {
                    ticks: {
                      callback: (v) => toCurrencyString(Number(v)),
                    },
                  },
                  x: {
                    type: 'time',
                    time: {
                      unit: 'day',
                    },
                    min: minDate,
                    max: maxDate,
                    ticks: {
                      major: { enabled: true },
                    },
                  },
                },
                interaction: {
                  intersect: false,
                  mode: 'nearest',
                },
              }}
            ></Chart>
          </>
        )}
      </Box>
    </>
  )
}
export default CommercialsProductPriceDetails
